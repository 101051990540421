import AdUnitWithPosition from "src/components/forsiden/AdUnitWithPosition";
import { AdUnit } from "src/lib/contexts/AdContext";
import { ICommonAppData } from "src/lib/types/ICommonAppData";
import { IAdEnvironment } from "src/lib/types/Settings";

export function mergeAdUnits(predefined: AdUnit[], configured?: AdUnit[] | null): AdUnit[] {
    if (!configured || configured.length === 0) {
        return predefined;
    }

    // replace all 'predefined' ad units with the same ad unit in 'configured' with the same targetId
    // NOTE: ORDER of ad units seems to be significant, så make sure to keep predefined ordre
    let result = predefined.map(predef => {
        const targetId = predef.targetId;
        const conf = configured.find(c => c.targetId === targetId);
        if (conf) {
            return conf;
        }
        return predef;
    });
    const missing = configured.filter( c => !predefined.some( p => p.targetId === c.targetId ));
    if( missing.length > 0 ) {
       result = result.concat(missing);
    }


    return result;
}

export function findAdEnvironment(appData: ICommonAppData): IAdEnvironment | undefined {
    const envName = process.env.AD_ENV_NAME ?? "PROD";
    if (appData?.settings?.adEnvironments) {
        return appData.settings.adEnvironments.find(env => env.adEnvName === envName);
    }
}

export function useOverriddenAdunits(predefined: AdUnit[], configured?: AdUnit[] | null): AdUnitWithPosition[] {
    //console.debug("PREDEFINED", predefined);
    //console.debug("CONFIGURED", configured);
    const merged = mergeAdUnits(predefined, configured);
    //console.debug("MERGED", merged);
    return merged;
}

export function adUnitMakeSafeSerializable(adUnits: AdUnitWithPosition[]): AdUnitWithPosition[] {
    return adUnits.map(adUnit => ({
        ...adUnit,
        auH: adUnit.auH === undefined ? null : adUnit.auH,
        auW: adUnit.auW === undefined ? null : adUnit.auW,
        clearTarget: adUnit.clearTarget === undefined ? null : adUnit.clearTarget,
        collapsible: adUnit.collapsible === undefined ? null : adUnit.collapsible,
        container: adUnit.container === undefined ? null : adUnit.container,
        display: adUnit.display === undefined ? null : adUnit.display,
        floorPrice: adUnit.floorPrice === undefined ? null : adUnit.floorPrice,
        isPackshot: adUnit.isPackshot === undefined ? null : adUnit.isPackshot,
        kv: adUnit.kv === undefined ? null : adUnit.kv,
        onAllResponses: adUnit.onAllResponses === undefined ? null : adUnit.onAllResponses,
        onError: adUnit.onError === undefined ? null : adUnit.onError,
        onImpressionResponse: adUnit.onImpressionResponse === undefined ? null : adUnit.onImpressionResponse,
        onNoMatchedAds: adUnit.onNoMatchedAds === undefined ? null : adUnit.onNoMatchedAds,
        onPageLoad: adUnit.onPageLoad === undefined ? null : adUnit.onPageLoad,
        onResponse: adUnit.onResponse === undefined ? null : adUnit.onResponse,
        onViewable: adUnit.onViewable === undefined ? null : adUnit.onViewable,
        onVisible: adUnit.onVisible === undefined ? null : adUnit.onVisible,
        position: adUnit.position === undefined ? null : adUnit.position,
        protocol: adUnit.protocol === undefined ? null : adUnit.protocol,
        ps: adUnit.ps === undefined ? null : adUnit.ps,
        requestMode: adUnit.requestMode === undefined ? null : adUnit.requestMode,
        requestTiming: adUnit.requestTiming === undefined ? null : adUnit.requestTiming,
        resizeOnPageLoad: adUnit.resizeOnPageLoad === undefined ? null : adUnit.resizeOnPageLoad,
        showAfterContentIf: adUnit.showAfterContentIf === undefined ? null : adUnit.showAfterContentIf,
        targetId: adUnit.targetId === undefined ? null : adUnit.targetId,
        useCookies: adUnit.useCookies === undefined ? null : adUnit.useCookies,
    }));
}